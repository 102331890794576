var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-snackbar',{attrs:{"absolute":"","top":"","right":"","color":"success"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(this.$route.params.id ? '게시물이 수정되었습니다.' : '게시물이 등록되었습니다')+" ")]),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"rules":_vm.rules.title,"color":"purple darken-2","label":"제목","required":""},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('small',[_vm._v("내용")]),_c('div',{staticClass:"my-5"},[(_vm.editor)?_c('div',[_c('v-btn',{class:{ 'is-active': _vm.editor.isActive('link') },on:{"click":_vm.setLink}},[_c('v-icon',[_vm._v("mdi-link-variant")])],1),_c('v-btn',{attrs:{"disabled":!_vm.editor.isActive('link')},on:{"click":function($event){_vm.editor
                    .chain()
                    .focus()
                    .unsetLink()
                    .run()}}},[_c('v-icon',[_vm._v("mdi-link-variant-off")])],1),_c('v-btn',{on:{"click":function($event){_vm.editor
                    .chain()
                    .focus()
                    .setHardBreak()
                    .run()}}},[_c('v-icon',[_vm._v("mdi-wrap")])],1),_c('editor-content',{attrs:{"editor":_vm.editor}})],1):_vm._e()]),_c('EditorContent',{attrs:{"editor":_vm.editor}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-select',{attrs:{"items":_vm.categories,"rules":_vm.rules.category,"color":"pink","label":"카테고리","required":""},model:{value:(_vm.form.category),callback:function ($$v) {_vm.$set(_vm.form, "category", $$v)},expression:"form.category"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[(_vm.form.category == '비디오 컨텐츠')?_c('v-text-field',{attrs:{"rules":_vm.rules.youtubeUrl,"color":"purple darken-2","label":"YouTube 링크"},model:{value:(_vm.form.youtubeUrl),callback:function ($$v) {_vm.$set(_vm.form, "youtubeUrl", $$v)},expression:"form.youtubeUrl"}}):_vm._e(),(_vm.form.category == '크레파스 워십')?_c('v-text-field',{attrs:{"rules":_vm.rules.imageUrl,"color":"purple darken-2","label":"이미지 링크"},model:{value:(_vm.form.imageUrl),callback:function ($$v) {_vm.$set(_vm.form, "imageUrl", $$v)},expression:"form.imageUrl"}}):_vm._e()],1)],1)],1),_c('v-card-actions',[(this.$route.params.id)?_c('v-btn',{staticClass:"my-5 ml-3",attrs:{"depressed":"","color":"#9575cd","dark":"","onclick":"history.back()"}},[_vm._v("Go Back")]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.resetForm}},[_vm._v(" 초기화 ")]),_c('v-btn',{attrs:{"disabled":!_vm.formIsValid,"text":"","color":"primary","type":"submit"},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(this.$route.params.id ? '수정하기' : '등록하기')+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }